import {createRouter, createWebHistory} from "vue-router";
import Error from "@/view/error/Error.vue";

const routerHistory = createWebHistory();

const router = createRouter({
    history: routerHistory,
    routes: [
        {
            path: '/',
            component: () => import('@/view/Index'),
            redirect: '/home',
            children: [
                {
                    path: '/home',
                    component: () => import('@/view/Home'),
                    name: 'home',
                    meta: {title: '主页'}
                },
                {
                    path: '/category/:id',
                    component: () => import('@/view/category/Category'),
                    name: 'category',
                    meta: {title: '分类'}
                },
                {
                    path: '/archive',
                    component: () => import('@/view/archive/Archive'),
                    name: 'archive',
                    meta: {title: '归档'}
                },
                {
                    path: '/friends',
                    component: () => import('@/view/friends/Friends'),
                    name: 'friends',
                    meta: {title: '友链'}
                },
                {
                    path: '/blog',
                    component: () => import('@/view/blog/Blog'),
                    name: 'blog',
                    meta: {title: '博客'}
                },
                {
                    path: '/about',
                    component: () => import('@/view/about/About'),
                    name: 'about',
                    meta: {title: '关于'}
                },
                {
                    path: '/tag',
                    component: () => import('@/view/tag/Tag'),
                    name: 'tag',
                    meta: {title: '标签'}
                },
                {
                    path:'/moments',
                    component: () => import('@/components/live/Moments'),
                    name:'moments',
                    meta:{title:'动态'}
                }
            ]
        },
        {
            path: '/error',
            component: Error,
            name: 'error',
            meta: {title: '出错了！当前界面不存在'}
        },
    ]
})

export default router

router.beforeEach((to, from, next) => {

    if (to.matched.length === 0) {
        document.title = to.meta.title + " - Ronny's Blog"
        next('/error')
    } else {
        document.title = to.meta.title + " - Ronny's Blog"
        next()
    }
})

