import {defineStore} from "pinia";
import {getCategoryBlogs} from "@/api/category";


export const useCategoryBlog = defineStore({
    id: 'categoryBlogStore',
    state: () => {
        return {
            categoryBlogs: []
        }
    },
    actions: {
        getCategoryBlogs() {
            getCategoryBlogs().then(res => {
                if (res.code === 200) {
                    this.categoryBlogs = res.data
                    this.categoryBlogs = this.categoryBlogs.filter(res =>{
                        return res.firstBlog !== null;
                    })
                }
            }).catch(() => {
            })
        }
    }

})
