import moment from 'moment'

//设置moment国际化语言
moment.locale('zh-cn')

export const dateFormat = (value) =>{
    return moment(value,moment.ISO_8601).format('YYYY-MM-DD HH:mm:ss')
}

export const dateFormatType = (value,format) =>{
    return moment(value,moment.ISO_8601).format(format)
}

//相对时间大于一个月，显示详细时间
export const dateFromNow = (value) => {
    if (moment().diff(moment(value)) > 2592000000) {
        return moment(value,moment.ISO_8601).format('YYYY-MM-DD HH:mm')
    }
    return moment(value,moment.ISO_8601).fromNow()
}

