import SidebarSlot from "@/components/sidebar/SidebarSlot.vue";
import { storeToRefs } from "pinia";
import { useSidebar } from "@/store/sidebar";
import { dateFromNow } from "@/utils/dateTimeFormat";
export default {
  name: "BlogNotice",
  components: {
    SidebarSlot
  },
  setup() {
    const {
      blogNotice
    } = storeToRefs(useSidebar());
    return {
      dateFromNow,
      blogNotice
    };
  }
};