import axios from '@/plugins/axios'

export function getBlogById(id){
    return axios({
        url:'blog',
        method:'GET',
        params:{
            id
        }
    })
}

export function getBlogList(){
    return axios({
        url:'blogs',
        method:'GET'
    })
}

export function getSearchBlogs(value){
    return axios({
        url:'/blog/search',
        method:'GET',
        params:{
            key:value
        }
    })
}
