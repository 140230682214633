import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import CategoryBlogItem from "@/components/categoryBlog/CategoryBlogItem.vue";
import { useCategoryBlog } from "@/store/categoryBlog";
export default {
  __name: 'CategoryBlogList',
  setup(__props) {
    const categoryBlog = useCategoryBlog();
    return (_ctx, _cache) => {
      return _unref(categoryBlog).categoryBlogs.length !== 0 ? (_openBlock(), _createBlock(CategoryBlogItem, {
        key: 0,
        categoryBlog: _unref(categoryBlog).categoryBlogs
      }, null, 8, ["categoryBlog"])) : _createCommentVNode("", true);
    };
  }
};