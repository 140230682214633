import { ref } from "vue";
export default {
  name: "BlogPage",
  props: ['pageCount'],
  setup() {
    let pageSize = ref(1);
    function handleCurrentChange(newPage) {
      pageSize.value = newPage;
    }
    return {
      handleCurrentChange,
      pageSize
    };
  }
};