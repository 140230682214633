import { defineStore } from "pinia";
import { getSite } from "@/api";
export const useSidebar = defineStore('sidebarInfo', {
  state: () => ({
    everyDayWord: {},
    introduce: {},
    randomBlogs: [],
    tags: [],
    categoryList: [],
    blogNotice: {},
    blogFlag: true
  }),
  actions: {
    setSidebarInfo() {
      getSite().then(res => {
        if (res.code === 200) {
          this.everyDayWord = res.data.everyDayWord;
          this.introduce = res.data.introduce;
          this.randomBlogs = res.data.randomBlogs;
          this.tags = res.data.tags;
          this.categoryList = res.data.categoryList;
          this.blogNotice = res.data.blogNotice;
          if (this.blogNotice == null) {
            this.blogNotice = {
              content: '没有新公告',
              createTime: 0
            };
          }
        } else {
          ElNotification({
            title: '请求失败',
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        ElNotification({
          title: '请求失败',
          message: '服务器异常',
          type: 'error'
        });
      });
    }
  }
});