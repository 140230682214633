import SidebarSlot from "@/components/sidebar/SidebarSlot.vue";
import { reactive, toRefs } from "vue";
import sidebar, { useSidebar } from "@/store/sidebar";
import { storeToRefs } from "pinia";
export default {
  name: "RandomArticle",
  components: {
    SidebarSlot
  },
  setup() {
    const {
      randomBlogs
    } = storeToRefs(useSidebar());
    return {
      randomBlogs
    };
  }
};