import axios from '@/plugins/axios'

export function getBlogListByCategoryId(id,pageSize){
    return axios({
        url:'category',
        method:'GET',
        params:{
            id,
            pageSize
        }
    })
}

export function getCategoryBlogs(){
    return axios({
        url:'/category/categoryBlogs',
        method:'GET',
    })
}
