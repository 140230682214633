import axios from "axios";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from "@/router";
const request = axios.create({
    baseURL: 'https://www.api.wrqj.top/blog/',
    // baseURL: 'http://localhost:8899/',
    timeout: 10000,
})
// 请求拦截
request.interceptors.request.use(
    config => {
        NProgress.start()
        const name = router.currentRoute.value.name;
        if (name === 'about' || name === 'blog' || name === 'friends') {
            const token = localStorage.getItem("giteeToken")
            if(token !== null && token !== ''){
                config.headers.Authorization = token
            }
        }
        return config
    }
)

// 响应拦截
request.interceptors.response.use(
    config => {
        NProgress.done()
        if(config.data.code === 500){
            router.push("/error")
        }else if(config.data.code === 402){
            // localStorage.removeItem('giteeToken')
            // localStorage.removeItem('userInfo')
        }
        return config.data
    }
)

export default request
