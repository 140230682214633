import Bottom from "@/components/index/Bottom.vue";
import HeaderMenus from "@/components/index/HeaderMenus.vue";
import BlogList from "@/components/Blog/BlogList.vue";
import EveryDayWord from "@/components/sidebar/EveryDayWord.vue";
import RandomArticle from "@/components/sidebar/RandomArticle.vue";
import Tags from "@/components/sidebar/Tags.vue";
import router from "../router";
import { watchEffect } from "vue";
import IntroduceMe from "@/components/sidebar/IntroduceMe.vue";
import { useSidebar } from "@/store/sidebar";
import Catalogue from "@/components/sidebar/Catalogue.vue";
import BlogNotice from "@/components/sidebar/BlogNotice.vue";
import backScreenTop from "@/hooks/backScreenTop";
import CategoryBlogList from "@/components/categoryBlog/CategoryBlogList.vue";
import { useCategoryBlog } from "@/store/categoryBlog";
export default {
  name: "Index",
  components: {
    CategoryBlogList,
    BlogNotice,
    Catalogue,
    IntroduceMe,
    Tags,
    RandomArticle,
    HeaderMenus,
    Bottom,
    BlogList,
    EveryDayWord
  },
  setup() {
    const sidebar = useSidebar();
    sidebar.setSidebarInfo();
    const categoryBlog = useCategoryBlog();
    categoryBlog.getCategoryBlogs();
    const notShowRouterName = ["friends", "about", "moments", "archive"];
    watchEffect(() => {
      const routerName = router.currentRoute.value.path;
      sidebar.blogFlag = true;
      backScreenTop();
    });

    //需要隐藏sidebar的组件
    function showComponent() {
      const routerName = router.currentRoute.value.name;
      if (routerName === "blog") {
        return sidebar.blogFlag;
      }
      return !notShowRouterName.includes(routerName);
    }
    return {
      showComponent
    };
  }
};