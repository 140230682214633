//预览组件以及样式
import Vmpreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
// VuePress主题以及样式 (这里也可以选择github主题)
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
// Prism
import Prism from 'prismjs'
import 'prismjs/components/prism-json'
// emoji
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css'
// 显示代码行数
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
// 快速复制代码
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';

//选择使用主题
Vmpreview.use(vuepressTheme,{
    Prism
})
//使用表情
Vmpreview.use(createEmojiPlugin)
//显示代码行
Vmpreview.use(createLineNumbertPlugin())
//快速复制代码
Vmpreview.use(createCopyCodePlugin());

export default Vmpreview