import SidebarSlot from "@/components/sidebar/SidebarSlot.vue";
import { storeToRefs } from "pinia";
import { useSidebar } from "@/store/sidebar";
export default {
  name: "IntroduceMe",
  components: {
    SidebarSlot
  },
  setup() {
    const {
      introduce
    } = storeToRefs(useSidebar());
    return {
      introduce
    };
  }
};