import SidebarSlot from "@/components/sidebar/SidebarSlot.vue";
import { useSidebar } from "@/store/sidebar";
import { storeToRefs } from "pinia";
export default {
  name: "EveryDayWord",
  components: {
    SidebarSlot
  },
  setup() {
    const {
      everyDayWord
    } = storeToRefs(useSidebar());
    return {
      everyDayWord
    };
  }
};