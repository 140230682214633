import randomColor from "@/hooks/randomColor";
import { dateFormat, dateFromNow } from "@/utils/dateTimeFormat";
export default {
  name: "BlogItem",
  methods: {
    randomColor
  },
  props: ["item"],
  setup() {
    return {
      dateFromNow,
      dateFormat
    };
  }
};