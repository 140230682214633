import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9d6c93c0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "middle container"
};
const _hoisted_2 = {
  class: "row"
};
const _hoisted_3 = {
  key: 0,
  class: "sidebar middle_right col-lg-4"
};
const _hoisted_4 = {
  class: "sidebar_real"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderMenus = _resolveComponent("HeaderMenus");
  const _component_router_view = _resolveComponent("router-view");
  const _component_IntroduceMe = _resolveComponent("IntroduceMe");
  const _component_BlogNotice = _resolveComponent("BlogNotice");
  const _component_EveryDayWord = _resolveComponent("EveryDayWord");
  const _component_RandomArticle = _resolveComponent("RandomArticle");
  const _component_Tags = _resolveComponent("Tags");
  const _component_CategoryBlogList = _resolveComponent("CategoryBlogList");
  const _component_Bottom = _resolveComponent("Bottom");
  const _component_el_backtop = _resolveComponent("el-backtop");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_HeaderMenus, {
    class: "hm"
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["middle_blog col-md-12", $setup.showComponent() ? 'col-lg-8' : null])
  }, [_createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [(_openBlock(), _createBlock(_KeepAlive, {
      include: "home archive photo friends"
    }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
    _: 1
  })], 2), $setup.showComponent() ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_IntroduceMe), _createVNode(_component_BlogNotice), _createVNode(_component_EveryDayWord), _createVNode(_component_RandomArticle), _createVNode(_component_Tags)])])) : _createCommentVNode("", true)]), $setup.showComponent() ? (_openBlock(), _createBlock(_component_CategoryBlogList, {
    key: 0,
    class: "col-8"
  })) : _createCommentVNode("", true)]), _createVNode(_component_Bottom, {
    class: "b_bottom"
  }), _createVNode(_component_el_backtop, {
    right: 100,
    bottom: 100
  })], 64);
}