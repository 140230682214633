import { ref } from "vue";
export default {
  name: "Catalogue",
  props: ['titles', 'handleAnchorClick'],
  setup() {
    let flag = ref(false);
    function isShow() {
      flag.value = !flag.value;
    }
    return {
      isShow,
      flag
    };
  }
};