import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useSidebar } from "@/store/sidebar";
import SearchBlog from "@/components/search/SearchBlog.vue";
export default {
  name: "HeaderMenus",
  components: {
    SearchBlog
  },
  setup() {
    const isShow = ref(false);
    const categoryFlag = ref(false);
    const liveFlag = ref(false);
    const searchShow = ref(false);
    const searchValue = ref('');
    function toggle() {
      isShow.value = !isShow.value;
    }
    function closeSearchBlog() {
      searchShow.value = !searchShow.value;
    }
    const {
      categoryList
    } = storeToRefs(useSidebar());
    return {
      isShow,
      toggle,
      categoryFlag,
      categoryList,
      liveFlag,
      searchShow,
      searchValue,
      closeSearchBlog
    };
  }
};