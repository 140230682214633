import {reactive} from "vue";

export default function (){
    let color = reactive({
        bgColor: [
            '#1abc9c', '#2ecc71', '#3498db', '#9b59b6',
            '#34495e', '#f1c40f', '#e67e22', '#e74c3c',
            '#ee5a24', '#9980fa', '#8c7ae6', '#f79f1f',
            '#FFCCFF','#FFCC33','#CC66CC','#CC33FF',
            '#FF3366','#33FFFF','#66CCFF','#33FF00'
        ]
    })
    function randomRGB() {
        const index = Math.floor((Math.random() * color.bgColor.length))
        return {backgroundColor: color.bgColor[index]}
    }
    return randomRGB()
}
