import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "m_page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_pagination, {
    onCurrentChange: $setup.handleCurrentChange,
    background: "",
    layout: "prev, pager, next",
    "current-page": $setup.pageSize,
    "page-count": $props.pageCount,
    "hide-on-single-page": ""
  }, null, 8, ["onCurrentChange", "current-page", "page-count"])]);
}