import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-23784556"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "blogs"
};
const _hoisted_2 = {
  class: "m_blog post-item-card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BlogItem = _resolveComponent("BlogItem");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.blogs, item => {
    return _openBlock(), _createBlock(_component_BlogItem, {
      key: item.id,
      item: item
    }, null, 8, ["item"]);
  }), 128))])]);
}