import SidebarSlot from "@/components/sidebar/SidebarSlot.vue";
import randomColor from "@/hooks/randomColor";
import { useSidebar } from "@/store/sidebar";
import { storeToRefs } from "pinia";
import { onMounted, reactive } from "vue";
export default {
  name: "Tags",
  methods: {
    randomColor
  },
  components: {
    SidebarSlot
  },
  setup() {
    const {
      tags
    } = storeToRefs(useSidebar());
    return {
      tags
    };
  }
};