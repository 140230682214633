
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '../public/css/global.css'

import {createPinia} from "pinia";
import vmpreviewPlugin from "@/plugins/vmpreviewPlugin";
import '@/assets/iconfont.css'
import '@/assets/iconfont'
const app = createApp(App)
app.use(router)
app.use(createPinia())
// 引入 v-md-editor预览组件
app.use(vmpreviewPlugin)
app.mount('#app')
//节流

app.directive('throttle',{
    created:function (el,binding){
        let [fn,event = 'click',time = 300] = binding.value
        let now,preTime
        el.addEventListener(event,()=>{
            now = new Date()
            if(!preTime || now - preTime > time){
                preTime = now
                fn()
            }
        })
    }
})




//防抖
